import { React, Table } from '@1337lawyers/design';

export const ReviewerInfo = () => (
  <React.Fragment>
    <p>Total Unclaimed VRs</p>

    <p>Expedited Unclaimed VRs</p>

    <p>Total Waiting for Info VRs</p>

    <Table
      headers={[
        'Name',
        'Reviews Pending',
        'Reviews Waiting for Info',
        'Last VR Result',
        'Last Login',
      ]}
      data={[]}
    />
  </React.Fragment>
);
