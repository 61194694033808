import { Container, Query, React, Section, Table } from '@1337lawyers/design';
import { CreatePortalUser } from './create';

import * as LIST_PORTAL_USERS from './listPortalUsers.gql';

export const PortalUsers = () => (
  <React.Fragment>
    <Section>
      <Container>
        <CreatePortalUser />
      </Container>
    </Section>
    <Section>
      <Container>
        <Query query={LIST_PORTAL_USERS}>
          {({ loading, error, data }) => {
            if (loading) {
              return 'Loading...';
            }
            if (error) {
              return `Error! ${error.message}`;
            }

            return (
              <Table
                headers={['Id', 'Has API Access']}
                data={data.listPortalUsers.map(portalUser => [
                  portalUser.id,
                  `${portalUser.hasApiAccess}`,
                ])}
              />
            );
          }}
        </Query>
      </Container>
    </Section>
  </React.Fragment>
);

export default PortalUsers;
