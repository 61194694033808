import { Container, Markdown, React, Section } from '@1337lawyers/design';

import authorizations from './authorizations.md';

export const Guide = () => (
  <Section>
    <Container>
      <Markdown text={authorizations} />
    </Container>
  </Section>
);
