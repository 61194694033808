import { Container, Query, React, Section, Table } from '@1337lawyers/design';
import { CreateReviewer } from './create';

import * as LIST_REVIEWERS from './listReviewers.gql';

export const Reviewers = () => (
  <React.Fragment>
    <Section>
      <Container>
        <CreateReviewer />
      </Container>
    </Section>
    <Section>
      <Container>
        <Query query={LIST_REVIEWERS}>
          {({ loading, error, data }) => {
            if (loading) {
              return 'Loading...';
            }
            if (error) {
              return `Error! ${error.message}`;
            }

            return (
              <Table
                headers={['Id', 'Bar #', 'bar jurisdiction']}
                data={data.listReviewers.map(reviewer => [
                  reviewer.id,
                  reviewer.barNumber,
                  reviewer.barJurisdiction,
                ])}
              />
            );
          }}
        </Query>
      </Container>
    </Section>
  </React.Fragment>
);

export default Reviewers;
