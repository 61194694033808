import {
  Formik,
  Mutation,
  NumberInput,
  React,
  SubmitAndReset,
  TextInput,
  Yup,
} from '@1337lawyers/design';

import * as CREATE_REVIEWER from './createReviewer.gql';

interface ICreateReviewerValues {
  personId: string;
  barJurisdiction: string;
  barNumber?: number;
}

export const CreateReviewer = () => (
  <Mutation mutation={CREATE_REVIEWER}>
    {(createReviewer, { data }) => (
      <Formik
        initialValues={{
          personId: '',
          barJurisdiction: '',
          barNumber: undefined,
        }}
        onSubmit={async (values: ICreateReviewerValues, { setSubmitting }) => {
          setSubmitting(true);
          await createReviewer({
            variables: { ...values },
          }).catch(() => {
            setSubmitting(false);
          });
          console.log(data);
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          personId: Yup.string().required(),
        })}
        render={({
          values,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          errors,
          handleReset,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextInput
              touched={touched}
              errors={errors}
              values={values}
              label="Person Id"
              handleBlur={handleBlur}
              handleChange={handleChange}
              value="personId"
            />

            <TextInput
              touched={touched}
              errors={errors}
              values={values}
              label="Bar Jurisdiction"
              handleBlur={handleBlur}
              handleChange={handleChange}
              value="barJurisdiction"
            />

            <NumberInput
              touched={touched}
              errors={errors}
              values={values}
              label="Bar Number"
              handleBlur={handleBlur}
              handleChange={handleChange}
              value="barNumber"
            />

            <SubmitAndReset
              errors={errors}
              isSubmitting={isSubmitting}
              submitButtonText="Create Reviewer"
              handleReset={handleReset}
            />
          </form>
        )}
      />
    )}
  </Mutation>
);
