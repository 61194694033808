import { React } from '@1337lawyers/design';

const PendingReviewerVerificationRequests = () => (
  <React.Fragment>
    <div className="columns">
      <div className="column is-10 is-offset-1">
        Pending Verification Requests
      </div>
    </div>
  </React.Fragment>
);

export default PendingReviewerVerificationRequests;
