import {
  Breadcrumbs,
  React,
  Redirect,
  Route,
  Switch,
  withRouter,
} from '@1337lawyers/design';

import { AvailableVerificationRequests } from './available';
import { CompletedVerificationRequests } from './completed';
import { PendingVerificationRequests } from './pending';

interface IReviewerIndexProps {
  match: any;
}

const ReviewerIndex = ({ match }: IReviewerIndexProps) => (
  <React.Fragment>
    <Breadcrumbs />
    <Switch>
      <Route
        path={match.url + '/available'}
        component={AvailableVerificationRequests}
      />
      <Route
        path={match.url + '/completed'}
        component={CompletedVerificationRequests}
      />
      <Route
        path={match.url + '/pending'}
        component={PendingVerificationRequests}
      />
      <Redirect exact={true} to={match.url + '/available'} />
    </Switch>
  </React.Fragment>
);

export default withRouter(ReviewerIndex);
