import { Container, Query, React, Section, Table } from '@1337lawyers/design';
import { CreateIssuer } from './create';

import * as LIST_ISSUERS from './listIssuers.gql';

export const Issuers = () => (
  <React.Fragment>
    <Section>
      <Container>
        <CreateIssuer />
      </Container>
    </Section>
    <Section>
      <Container>
        <Query query={LIST_ISSUERS}>
          {({ loading, error, data }) => {
            if (loading) {
              return 'Loading...';
            }
            if (error) {
              return `Error! ${error.message}`;
            }

            return (
              <Table
                headers={['Id', 'Has API Access']}
                data={data.listIssuers.map(issuer => [
                  issuer.id,
                  `${issuer.hasApiAccess}`,
                ])}
              />
            );
          }}
        </Query>
      </Container>
    </Section>
  </React.Fragment>
);

export default Issuers;
