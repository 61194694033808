import {
  Column,
  Columns,
  Container,
  Menu,
  React,
  Route,
  Section,
  Switch,
  withRouter,
} from '@1337lawyers/design';

import Admin from '../admin';
import Investor from '../investor';
import Issuer from '../issuer';
import Support from '../portalSupport';
import Reviewer from '../reviewer';
import Settings from '../settings';
import SuperAdmin from '../superAdmin';
import { PortalSplash } from './portalSplash';

interface IPortalProps {
  match: any;
}

const PortalComponent = ({ match }: IPortalProps) => {
  const portalMenu = [
    {
      issuer: [
        'Pending',
        'Completed',
        'Tracking',
        'New Verification',
        'Account History',
      ],
    },
    {
      investor: ['Verification Letters', 'New Self-Verification'],
    },
    {
      reviewer: ['Available', 'Pending', 'Completed'],
    },
    {
      support: [
        'Reviewer Support',
        'Reviewer Info',
        'Verification Requests',
        'Investor Invitations',
        'Users',
        'Guide',
      ],
    },
    {
      admin: ['Investors', 'Issuers', 'Portal Users', 'Credits'],
    },
    {
      superAdmin: [
        'API',
        'App Settings',
        'Billing',
        'Coupons',
        'Methods',
        'Payments',
        'Reviewers',
      ],
    },
  ];

  return (
    <Columns>
      <Menu
        title="Verify Investor"
        basePath={match.path}
        menu={portalMenu}
        navigation={portalMenu}
      />

      <Column>
        <Section>
          <Container>
            <Switch>
              <Route path={match.path + '/settings'} component={Settings} />
              <Route path={match.path + '/issuer'} component={Issuer} />
              <Route path={match.path + '/admin'} component={Admin} />
              <Route path={match.path + '/reviewer'} component={Reviewer} />
              <Route path={match.path + '/investor'} component={Investor} />
              <Route path={match.path + '/support'} component={Support} />
              <Route
                path={match.path + '/super-admin'}
                component={SuperAdmin}
              />

              <Route exact={true} path={match.path} component={PortalSplash} />
            </Switch>
          </Container>
        </Section>
      </Column>
    </Columns>
  );
};

export const Portal = withRouter(PortalComponent);
