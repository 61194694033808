import {
  Container,
  Markdown,
  Navbar,
  React,
  Section,
} from '@1337lawyers/design';

import privacyPolicy from './privacyPolicy.md';

export const Privacy = () => (
  <React.Fragment>
    <Navbar title="VerifyInvestor.com" navigation={['Portal']} />
    <Section>
      <Container>
        <Markdown text={privacyPolicy} />
      </Container>
    </Section>
  </React.Fragment>
);
