import { Link, React } from '@1337lawyers/design';

export const Footer = () => (
  <section className="section">
    <div className="container">
      <div className="columns is-multiline">
        <div className="column is-12">Copyright 2018 Verify Investor</div>
        <div className="column is-8">
          <div className="level">
            <span className="level-item">
              <Link to="/">Home</Link>
            </span>
            <span className="level-item">
              <Link to="/about">About</Link>
            </span>
            <span className="level-item">
              <Link to="/privacy">Privacy</Link>
            </span>
            <span className="level-item">
              <Link to="/terms">Terms</Link>
            </span>
            <span className="level-item">
              <Link to="/support">Support / FAQ</Link>
            </span>
            <span className="level-item">
              <Link to="/contact">Contact</Link>
            </span>
            <span className="level-item">
              <a href="https://blog.verifyinvestor.com" target="_blank">
                Blog
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
);
