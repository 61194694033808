import { Link, React } from '@1337lawyers/design';

const SettingsSplash = () => {
  return (
    <React.Fragment>
      <div className="level">
        <div className="level-right">
          <Link to="/portal/settings/edit">Edit</Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SettingsSplash;
