import { React } from '@1337lawyers/design';

const PendingIssuerVerificationRequests = () => (
  <React.Fragment>
    <div className="columns">
      <div className="column is-10 is-offset-1">Issuer Dashboard</div>
    </div>
  </React.Fragment>
);

export default PendingIssuerVerificationRequests;
