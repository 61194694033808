import {
  Formik,
  Mutation,
  React,
  SubmitAndReset,
  TextInput,
  Yup,
} from '@1337lawyers/design';

import * as CREATE_ISSUER from './createIssuer.gql';

interface ICreateIssuerValues {
  personId: string;
  barJurisdiction: string;
  barNumber?: number;
}

export const CreateIssuer = () => (
  <Mutation mutation={CREATE_ISSUER}>
    {(createIssuer, { data }) => (
      <Formik
        initialValues={{
          personId: '',
          barJurisdiction: '',
          barNumber: undefined,
        }}
        onSubmit={async (values: ICreateIssuerValues, { setSubmitting }) => {
          setSubmitting(true);
          await createIssuer({
            variables: { ...values },
          }).catch(() => {
            setSubmitting(false);
          });
          console.log(data);
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          personId: Yup.string().required(),
        })}
        render={({
          values,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          errors,
          handleReset,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextInput
              touched={touched}
              errors={errors}
              values={values}
              label="Person Id"
              handleBlur={handleBlur}
              handleChange={handleChange}
              value="personId"
            />

            <SubmitAndReset
              errors={errors}
              isSubmitting={isSubmitting}
              submitButtonText="Create Issuer"
              handleReset={handleReset}
            />
          </form>
        )}
      />
    )}
  </Mutation>
);
