import {
  Container,
  Markdown,
  Navbar,
  React,
  Section,
} from '@1337lawyers/design';

import * as questions from './questions.md';

export const Support = () => (
  <React.Fragment>
    <Navbar title="VerifyInvestor.com" navigation={['portal']} />
    <Section>
      <Container>
        <Markdown text={questions} />
      </Container>
    </Section>
  </React.Fragment>
);
