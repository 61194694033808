import {
  Container,
  Formik,
  Mutation,
  Navbar,
  React,
  Section,
  SubmitAndReset,
  TextArea,
  TextInput,
  Yup,
} from '@1337lawyers/design';

import * as CONTACT_FORM from './contactForm.gql';

interface IContactFormValues {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export const Contact = () => (
  <React.Fragment>
    <Navbar title="VerifyInvestor.com" navigation={['Portal']} />
    <Section>
      <Container>
        <Mutation mutation={CONTACT_FORM}>
          {(contactForm, { data }) => (
            <Formik
              initialValues={{
                name: '',
                email: '',
                subject: '',
                message: '',
              }}
              onSubmit={async (
                values: IContactFormValues,
                { setSubmitting }
              ) => {
                setSubmitting(true);
                await contactForm({
                  variables: { ...values },
                }).catch(() => {
                  setSubmitting(false);
                });
                setSubmitting(false);
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .required()
                  .email(),
                name: Yup.string()
                  .required()
                  .min(2),
                subject: Yup.string()
                  .required()
                  .min(2),
                message: Yup.string()
                  .required()
                  .min(10),
              })}
              render={({
                values,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                errors,
                handleReset,
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextInput
                    touched={touched}
                    errors={errors}
                    values={values}
                    label="Name"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value="name"
                  />

                  <TextInput
                    touched={touched}
                    errors={errors}
                    values={values}
                    label="Email"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value="email"
                  />

                  <TextInput
                    touched={touched}
                    errors={errors}
                    values={values}
                    label="Subject"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value="subject"
                  />

                  <TextArea
                    touched={touched}
                    errors={errors}
                    values={values}
                    label="Message"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value="message"
                  />

                  <SubmitAndReset
                    errors={errors}
                    isSubmitting={isSubmitting}
                    submitButtonText="Send Message"
                    handleReset={handleReset}
                  />
                </form>
              )}
            />
          )}
        </Mutation>
      </Container>
    </Section>
  </React.Fragment>
);
