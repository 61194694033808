import { L33tSetup, React } from '@1337lawyers/design';

import enMessages from './locales/english';

import { RootRoutes } from './routes/root/root';

export const App = () => {
  return (
    <L33tSetup englishMessages={enMessages}>
      <RootRoutes />
    </L33tSetup>
  );
};
