import { AuthRoute, React, Route, Switch } from '@1337lawyers/design';

import './root.sass';

import { About } from '../about/about';
import { Contact } from '../contact/contact';
import { Portal } from '../portal/portal';
import { Privacy } from '../privacy/privacy';
import { Splash } from '../splash/splash';
import { Support } from '../support/support';
import { Terms } from '../terms/terms';

import { Footer } from './footer';

export const RootRoutes = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact={true} path="/" component={Splash} />

        <AuthRoute path="/portal" component={Portal} />

        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/support" component={Support} />
        <Route path="/terms" component={Terms} />
      </Switch>
      <Footer />
    </React.Fragment>
  );
};
