import { React } from '@1337lawyers/design';

import { Breadcrumbs, Route } from '@1337lawyers/design';

import SettingsEdit from './edit';
import SettingsSplash from './splash';

interface ISettingsIndexProps {
  match: any;
}

const SettingsIndex = ({ match }: ISettingsIndexProps) => (
  <React.Fragment>
    <Breadcrumbs />
    <Route exact={true} path={match.url} component={SettingsSplash} />
    <Route path={match.url + '/edit'} component={SettingsEdit} />
  </React.Fragment>
);

export default SettingsIndex;
