import {
  Breadcrumbs,
  React,
  Redirect,
  Route,
  Switch,
  withRouter,
} from '@1337lawyers/design';

import Dashboard from './dashboard';
import NewVerification from './newVerification';

interface IInvestorIndexProps {
  match: any;
}

const InvestorIndex = ({ match }: IInvestorIndexProps) => (
  <React.Fragment>
    <Breadcrumbs />
    <Switch>
      <Route path={match.url + '/verification-letters'} component={Dashboard} />
      <Route
        path={match.url + '/new-self-verification'}
        component={NewVerification}
      />
      <Redirect exact={true} to={match.url + '/verification-letters'} />
    </Switch>
  </React.Fragment>
);

export default withRouter(InvestorIndex);
