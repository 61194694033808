import { Container, Query, React, Section, Table } from '@1337lawyers/design';
import { CreateVerificationMethod } from './create';

import * as LIST_VERIFICATION_METHODS from './listVerificationMethods.gql';

export const VerificationMethods = () => (
  <React.Fragment>
    <Section>
      <Container>
        <CreateVerificationMethod />
      </Container>
    </Section>
    <Section>
      <Container>
        <Query query={LIST_VERIFICATION_METHODS}>
          {({ loading, error, data }) => {
            if (loading) {
              return 'Loading...';
            }
            if (error) {
              return `Error! ${error.message}`;
            }

            return (
              <Table
                headers={['Id', 'Name', 'Description']}
                data={data.listVerificationMethods.map(vm => [
                  vm.id,
                  vm.name,
                  vm.description,
                ])}
              />
            );
          }}
        </Query>
      </Container>
    </Section>
  </React.Fragment>
);

export default VerificationMethods;
