import {
  Breadcrumbs,
  React,
  Redirect,
  Route,
  Switch,
  withRouter,
} from '@1337lawyers/design';

import { Issuers } from './issuers/issuers';
import { PortalUsers } from './portalUsers/portalUsers';

interface IAdminProps {
  match: any;
}

const AdminComponent = ({ match }: IAdminProps) => {
  return (
    <React.Fragment>
      <Breadcrumbs />
      <Switch>
        <Route path={match.url + '/issuers'} component={Issuers} />
        <Route path={match.url + '/portal-users'} component={PortalUsers} />
        <Redirect exact={true} to={match.url + '/issuers'} />
      </Switch>
    </React.Fragment>
  );
};

export default withRouter(AdminComponent);
