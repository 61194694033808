import { Container, Navbar, React, Section } from '@1337lawyers/design';

export const About = () => {
  return (
    <React.Fragment>
      <Navbar title="VerifyInvestor.com" navigation={['Portal']} />
      <Section>
        <Container>About Verify Investor</Container>
      </Section>
    </React.Fragment>
  );
};

// <% @page_title = "About Verify Investor | Accredited Investor Certification" %>
// <% @page_description = "Started by an investment banker and securities attorney, Verify Investor is the leading source for verification of accredited investors. Call 818-925-6701 to know more!" %>

// <div id="billboard" class="group wide">
// 	<div class="inner">
// 		<h1>About Us</h1>
// 		<p>VerifyInvestor.com was started by an investment banker and a corporate and securities attorney. We are also entrepreneurs and investors. We understand the headaches involved with raising capital and investing. That’s why we’ve developed VerifyInvestor.com.</p>
// 	</div>
// </div>

// <div id="content">
// 	<div class="inner bios">
// 		<article class="group">
// 			<div class="pic">
// 				<%= image_tag "meta/about/jl.png" %>
// 			</div>

// 			<div class="bio">
// 				<h1>JL Law</h1>
// 				<h2>Co-Founder</h2>

// 				<p>As an active corporate and securities attorney with Homeier &amp; Law, P.C.,* JL is also an entrepreneur and investor. He previously worked at some of the world's largest and most prestigious law firms, including Shearman &amp; Sterling's New York office and Paul Hastings' Los Angeles office. In addition to routinely advising companies and investors on capital raises, he regularly speaks on capital raising, securities, and corporate issues to attorneys, entrepreneurs, and investors.</p>
// 				<p>For three consecutive years, JL was recognized as one of “Southern California’s Super Lawyers – Rising Stars,” placing him among the top 2.5 percent of the best up-and-coming attorneys in Southern California who are 40 years old or younger, or who have been practicing for 10 years or less. JL is a member of the California and New York state bars. He received his J.D. from Columbia University's School of Law and his B.A. in Rhetoric from UC Berkeley.</p>
// 				<p>*Homeier &amp; Law, P.C., is not affiliated with VerifyInvestor.com.</p>
// 			</div>
// 		</article>

// 		<article class="group">
// 			<div class="pic">
// 				<%= image_tag "meta/about/jt.png" %>
// 			</div>

// 			<div class="bio">
// 				<h1>JT Law</h1>
// 				<h2>Co-Founder</h2>

// 				<p>JT Law is a Co-founder at VerifyInvestor.com. JT is an active investment banker, entrepreneur, and investor. He previously worked as an investment banker at Credit Suisse, Morgan Stanley, and Salomon Smith Barney/Citigroup in their New York, Hong Kong, and London offices.</p>
// 				<p>JT executed transactions involving debt &amp; equity and mergers &amp; acquisitions across a variety of industries, including real estate, technology, and manufacturing. JT has also worked on venture capital and private equity transactions in China that have successfully exited through public offerings. He received his MBA from Columbia Business School and B.S. in Economics from the Wharton School, University of Pennsylvania.</p>
// 			</div>
// 		</article>
// 	</div>

// 	<div class="white">
// 		<article class="inner group the-idea">
// 			<h1>The Idea Behind VerifyInvestor.com</h1>

// 			<div class="left">
// 				<%= image_tag "meta/about/illustration.png" %>
// 			</div>

// 			<div class="right">
// 				<p>VerifyInvestor.com provides a fast, easy, and cost-effective method of compliance for companies seeking to verify their investors as accredited investors. At the same time, our platform gives those same investors peace of mind that their information is confidential and protected.</p>
// 				<p>Using VerifyInvestor.com constitutes taking “reasonable steps” and shields companies from the potential risks of noncompliance, including enforcement action and the possibility of having to return funds to disgruntled investors. We wanted something that’s good for both the company and its investors.</p>
// 			</div>
// 		</article>
// 	</div>
// </div>
