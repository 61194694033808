import { Query, React, Table } from '@1337lawyers/design';

import * as LIST_PEOPLE from './listPeople.gql';

export const Users = () => (
  <Query query={LIST_PEOPLE}>
    {({ loading, error, data }) => {
      if (loading) {
        return 'Loading...';
      }
      if (error) {
        return `Error! ${error.message}`;
      }

      return (
        <Table
          headers={['Id', 'Sub', 'Email']}
          data={data.listPeople.map(person => [
            person.id,
            person.sub,
            person.email,
          ])}
        />
      );
    }}
  </Query>
);
