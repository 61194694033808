import {
  Container,
  FormattedMessage,
  Navbar,
  React,
  Section,
} from '@1337lawyers/design';

export const Splash = () => {
  return (
    <React.Fragment>
      <Navbar title="VerifyInvestor.com" navigation={['Portal']} />
      <Section>
        <Container>
          <div className="hero">
            <h3 className="title is-3">Simple, Reliable, & Confidential</h3>
          </div>
        </Container>
      </Section>
      <Section>
        <Container>
          <p className="has-text-centered is-size-3">
            VerifyInvestor.com is the leading resource for verification of
            accredited investor status as required by federal laws. Get started
            now — It’s fast and easy.
          </p>
          <FormattedMessage id="marketing.apiButton" />

          <p>
            <FormattedMessage id="marketing.accreditedInvestorDescription" />
          </p>
          <p>
            We are committed to providing the best experience possible. Report a
            bug, typo, or other error, and get a 5% off coupon for your next
            visit.
          </p>
        </Container>
      </Section>
    </React.Fragment>
  );
};
