import {
  Container,
  Markdown,
  Navbar,
  React,
  Section,
} from '@1337lawyers/design';

import terms from './body.md';

export const Terms = () => {
  return (
    <React.Fragment>
      <Navbar title="VerifyInvestor.com" navigation={['Portal']} />
      <Section>
        <Container>
          <Markdown text={terms} />
        </Container>
      </Section>
    </React.Fragment>
  );
};
