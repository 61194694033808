import { React, Table } from '@1337lawyers/design';

export const AccountHistory = () => (
  <Table
    headers={[
      'Date',
      'Info / Requesting Party',
      'Deal',
      'Name',
      'Amount',
      'Price',
      'Receipt',
    ]}
    data={[]}
  />
);
