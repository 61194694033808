import {
  Breadcrumbs,
  React,
  Redirect,
  Route,
  Switch,
  withRouter,
} from '@1337lawyers/design';

import { Guide } from './guide/guide';
import { InvestorInvitations } from './investorInvitations';
import { ReviewerInfo } from './reviewerInfo';
import { ReviewerSupport } from './reviewerSupport';
import { Users } from './users';
import { VerificationRequests } from './verificationRequests';

interface IPortalSupportIndexProps {
  match: any;
}

const PortalSupportIndex = ({ match }: IPortalSupportIndexProps) => (
  <React.Fragment>
    <Breadcrumbs />
    <Switch>
      <Route
        path={match.url + '/reviewer-support'}
        component={ReviewerSupport}
      />
      <Route path={match.url + '/reviewer-info'} component={ReviewerInfo} />
      <Route
        path={match.url + '/verification-requests'}
        component={VerificationRequests}
      />
      <Route
        path={match.url + '/investor-invitations'}
        component={InvestorInvitations}
      />
      <Route path={match.url + '/users'} component={Users} />
      <Route path={match.url + '/guide'} component={Guide} />
      <Redirect exact={true} to={match.url + '/users'} />
    </Switch>
  </React.Fragment>
);

export default withRouter(PortalSupportIndex);
