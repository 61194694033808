import {
  Breadcrumbs,
  React,
  Redirect,
  Route,
  Switch,
  withRouter,
} from '@1337lawyers/design';

import Api from './api';
import AppSettings from './appSettings';
import Billing from './billing';
import Coupons from './coupons';
import Payments from './payments';
import { Reviewers } from './reviewers/reviewers';
import { VerificationMethods } from './verificationMethods/verificationMethods';

interface IIssuerIndexProps {
  match: any;
}

const IssuerIndex = ({ match }: IIssuerIndexProps) => (
  <React.Fragment>
    <Breadcrumbs />
    <Switch>
      <Route path={match.url + '/api'} component={Api} />
      <Route path={match.url + '/app-settings'} component={AppSettings} />
      <Route path={match.url + '/billing'} component={Billing} />
      <Route path={match.url + '/coupons'} component={Coupons} />
      <Route path={match.url + '/methods'} component={VerificationMethods} />
      <Route path={match.url + '/payments'} component={Payments} />
      <Route path={match.url + '/reviewers'} component={Reviewers} />
      <Redirect exact={true} to={match.url + '/payments'} />
    </Switch>
  </React.Fragment>
);

export default withRouter(IssuerIndex);
