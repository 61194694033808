import { React, Table } from '@1337lawyers/design';

export const VerificationRequests = () => (
  <Table
    headers={[
      'ID',
      'Date Requested',
      'Investor Name',
      'Issuer Name',
      'Method',
      'Reviewer Name',
      'Status',
      'PDF',
    ]}
    data={[]}
  />
);
