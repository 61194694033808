export default {
  'marketing.apiButton': 'Check out our API!',
  'marketing.accreditedInvestorDescription': `Recent federal laws require
  companies raising money through private placement capital raises where they
  generally solicit to verify that their investors are “accredited investors”. A
  simple questionnaire isn’t enough – companies must take further “reasonable
  steps” to prove their investors are “accredited investors” with potentially
  serious consequences for failing to do so. VerifyInvestor.com does just that.
  We help companies comply with their legal obligations while protecting
  investors' confidential information.`,
};
